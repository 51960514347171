import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { themes } from "./store/Themes";

const queryClient = new QueryClient();

ReactDOM.render(
    <ThemeProvider theme={themes}>
        <QueryClientProvider client={queryClient}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
              <AuthProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </AuthProvider>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </QueryClientProvider>
    </ThemeProvider>,
  document.getElementById("root")
);
