import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import AppLogout from './components/AppLogout';
import Spinner from './shared/Spinner';

const SignIn = lazy(() => import("./pages/Signin"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ActivateEmail = lazy(() => import("./pages/ActivateEmail"));
const SignUp = lazy(() => import("./pages/Signup"));
const SpecialSignUp = lazy(() => import("./pages/SpecialSignup"));
const Landing = lazy(() => import("./pages/landing"));

const Classroom = lazy(() => import("./student/classroom"));
const Videos = lazy(() => import("./student/videos"));
const SingleVideo = lazy(() => import("./student/singleVideo"));
const Tasks = lazy(() => import("./student/tasks"));
const Task = lazy(() => import("./student/task"));
const Quiz = lazy(() => import("./student/quiz"));
const SingleQuiz = lazy(() => import("./student/singleQuiz"));
const Profile = lazy(() => import("./student/profile"));
const Dashboard = lazy(() => import("./student/Dashboard"));
const UserScheme = lazy(() => import("./student/userscheme"));

const AdminClassroom = lazy(() => import("./admin/classroom"));
const AdminSingleVideo = lazy(() => import("./admin/singleVideo"));
const AdminVideos = lazy(() => import("./admin/videos"));
const AdminTasks = lazy(() => import("./admin/tasks"));
const AdminMarkTask = lazy(() => import("./admin/taskMark"));
const AdminStudentMarks = lazy(() => import("./admin/studentMarks"));
const AdminClassQuizUsers = lazy(() => import("./admin/classQuizUsers"));
const AdminQuiz = lazy(() => import("./admin/quiz"));
const AdminChat = lazy(() => import("./admin/chat"));
const School = lazy(() => import("./admin/school"));
const Sessions = lazy(() => import("./admin/sessions"));
const Weeks = lazy(() => import("./admin/weeks"));
const Student = lazy(() => import("./admin/student"));
const Teachers = lazy(() => import("./admin/teachers"));
const AdminDashboard = lazy(() => import("./admin/Dashboard"));
const AdminUserScheme = lazy(() => import("./admin/userscheme"));


const AccessDenied = lazy(() => import("./errorpages/accessdenied"));
const Error404 = lazy(() => import("./errorpages/404page"));
const Error500 = lazy(() => import("./errorpages/500page"));

const ROLES = {
    'PRIVILEDGE_USER': "PRIVILEDGE_USER",
    "SCHOOL_ADMIN": "SCHOOL_ADMIN",
    "SCHOOL_TEACHER": "SCHOOL_TEACHER",
    "SCHOOL_STUDENT": "SCHOOL_STUDENT",
    "SPECIAL_STUDENT": "SPECIAL_STUDENT",
    "REGULAR_STUDENT": "REGULAR_STUDENT"
}

const AppRoutes = () => {
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                {/* <Route path="/resetpassword" element={<ResetPassword />} /> */}
                <Route path="/auth/change/password/:token" element={<ResetPassword />} /> {/* Reset Forgot Password */}
                <Route path="/auth/reset/password/:token" element={<ResetPassword />} /> {/* Verify Acount Reset Password */}
                <Route path="/auth/account/validate/:token" element={<ActivateEmail />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signup/special" element={<SpecialSignUp />} />
                <Route path="*" element={<Error404 />} />
                <Route path="/500" element={<Error500 />} />
                <Route path="/access/denied" element={<AccessDenied />} />

                {/* USERS */}
                <Route element={<PersistLogin />}>
                    <Route
                        element={
                            <RequireAuth allowedRoles={[ROLES.REGULAR_STUDENT, ROLES.SCHOOL_STUDENT, ROLES.SPECIAL_STUDENT]} />
                        }
                    >
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/classroom" element={<Classroom />} />
                        <Route path="/dashboard/videos" element={<Videos />} />
                        <Route path="/dashboard/video/single/:id" element={<SingleVideo />} />
                        <Route path="/dashboard/tasks" element={<Tasks />} />
                        <Route path="/dashboard/task/:id" element={<Task />} />
                        <Route path="/dashboard/quiz" element={<Quiz />} />
                        <Route path="/dashboard/quiz/single/:id" element={<SingleQuiz />} />
                        <Route path="/dashboard/profile" element={<Profile />} />
                        <Route path="/dashboard/my/scheme" element={<UserScheme />} />
                    </Route>

                    {/* ADMIN  AND SCHOOL_ADMIN*/}
                    <Route
                        element={
                            <RequireAuth allowedRoles={[ROLES.PRIVILEDGE_USER, ROLES.SCHOOL_ADMIN]} />
                        }
                    >
                        <Route path="/dashboard/admin/sessions" element={<Sessions />} />
                        <Route path="/dashboard/admin/weeks/:sessionUID" element={<Weeks />} />
                    </Route>

                    {/* ADMIN */}
                    <Route
                        element={
                            <RequireAuth allowedRoles={[ROLES.PRIVILEDGE_USER, ROLES.SCHOOL_ADMIN, ROLES.SCHOOL_TEACHER]} />
                        }
                    >
                        <Route path="/dashboard/admin" element={<AdminDashboard />} />
                        <Route path="/dashboard/admin/classroom" element={<AdminClassroom />} />
                        <Route path="/dashboard/admin/videos" element={<AdminVideos />} />
                        <Route path="/dashboard/admin/video/single/:id" element={<AdminSingleVideo />} />
                        <Route path="/dashboard/admin/tasks" element={<AdminTasks />} />
                        <Route path="/dashboard/admin/task/mark/:id" element={<AdminMarkTask />} />
                        <Route path="/dashboard/admin/quiz" element={<AdminQuiz />} />
                        <Route path="/dashboard/admin/class/quiz/users/:id" element={<AdminClassQuizUsers />} />
                        <Route path="/dashboard/admin/class/chat" element={<AdminChat />} />
                        <Route path="/dashboard/admin/student" element={<Student />} />
                        <Route path="/dashboard/admin/teacher" element={<Teachers />} />
                        <Route path="/dashboard/admin/students/scheme" element={<AdminUserScheme />} />
                        <Route path="/dashboard/admin/grades/user/:id" element={<AdminStudentMarks />} />
                        {/* <Route path="/dashboard/student/profile" element={<Profile />} /> */}
                    </Route>

                    <Route
                        element={
                            <RequireAuth allowedRoles={[ROLES.PRIVILEDGE_USER]} />
                        }
                    >
                        <Route path="/dashboard/admin/school" element={<School />} />
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
