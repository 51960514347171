import { createTheme } from "@material-ui/core";

export const themes = createTheme({
    palette: {
        primary: {
            main: "#013E5A"
        },
        secondary: {
            main: "#FF9900"
        }
    },
    typography: {
        fontFamily: "PT Sans"
    }
});